:root {
   --text-primary: #000;
   --bgPrimary: #fff;
   --bgSecondary: #F9F9F9;
   --pink: #0071bd;
   --light-blue: #e6f7ff;
   --border: #d9d9d9;
}

* {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
}

a:hover {
   color: var(--pink) !important;
}

.hidden {
   display: none;
}

.app {
   display: flex;
   overflow: hidden;
}

.navbar {
   flex: 0.2;
   background-color: rgb(0, 21, 41);
}

.main {
   flex: 0.8;
   width: 100%;

}

.routes {
   padding: 20px;
}

.nav__container {
   position: fixed;
   left: 0;
   margin: 10px;
   height: 100vh;
   margin: 0px;
   background-color: rgb(0, 21, 41);
}

.logo__container {
   padding: 1.5rem 1.5rem 2rem;
   background-color: #001529;
   display: flex;
   align-items: center;
   justify-content: space-between;
   width: 100%;
}

.logo__container > div {
   display: flex;
   gap: 1rem;
}

.logo {
   margin: 0 !important;
}


.ant-btn {
   padding: 0.25rem 0.75rem 2rem !important;
}

.menu__control-container {
   display: none !important;
   font-size: 1.2rem !important;
   background-color: var(--bgSecondary) !important;
   border: none !important;

}

.loader {
   height: 81vh;
   display: flex;
   justify-content: center;
   align-items: center;
}

@media screen and (max-width:1300px) {
   .main {
      margin-left: 50px;
   }
}

@media screen and (max-width:1170px) {
   .main {
      margin-left: 50px;
   }
}

@media screen and (max-width:1000px) {
   .main {
      margin-left: 100px;
   }
}

@media screen and (max-width:800px) {
   .app {
      flex-direction: column;
      overflow: hidden;
   }

   .navbar {
      flex: 1;
   }

   .main {
      flex: 1;
      margin-top: 90px;
      margin-left: 0px;
      margin-right: 10px;
   }

   .nav__container {
      height: 3rem;
      position: fixed;
      width: 100%;
      z-index: 100;
      background-color: var(--bgSecondary);
   }

   .menu__control-container {
      display: block !important;
      z-index: 5;
   }

   .ant__menu {
      position: absolute;
      right: 0px;
   }

   .home__title {
      font-size: 1.4rem !important;
   }

   .show__more {
      font-size: 1.3rem !important;
   }
}

.coin__detail-container {
   margin: 30px;
}

.coin__heading-container {
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   border-bottom: 1px solid var(--border);
   padding-top: 20px;
   padding-bottom: 20px;
   gap: 10px;
}

.coin__heading-container .coin-name {
   font-weight: 900;
   color: var(--pink);
}

.coin__heading-container p {
   font-size: 1rem;
   opacity: 0.9;
   margin-bottom: 20px;
}

.stats__container {
   display: flex;
   justify-content: space-between;
   align-items: center;
   gap: 40px;
}

.stats__container h2 {
   font-weight: 700;
   font-size: 1.4rem;
   margin-top: 20px;
   color: var(--pink);
}

.coin__details-heading {
   font-weight: 700 !important;
   margin-top: 20px !important;
   color: var(--pink) !important;
}

.coin__stats {
   display: flex;
   justify-content: space-between;
   border-bottom: 1px solid var(--border);
   font-size: 1rem;
   opacity: 0.9;
   padding: 20px;

}

.coin__stats-name {
   display: flex;
   gap: 10px;
   font-size: 1rem;
}

.stats {
   font-weight: 800;
}

.coin__value-statistics-heading p {
   font-size: 1rem;
   opacity: 0.9;
}

.coin__desc-link {
   display: flex;
   gap: 42px;
   margin-top: 44px;
   padding-top: 20px;
}

.coin__desc-link h2 {
   font-weight: 700;
   color: var(--pink);
}

.coin__desc-link p {
   font-size: 1rem;
   opacity: 0.9;
}

.coin__desc-link a {
   color: var(--pink);
}

.coin__desc-link h3 {
   font-weight: 700;
}

.coin__desc {
   flex: 0.5;
}

.coin__links {
   padding: 0px 20px;
   flex: 0.5;
}

.coin__link {
   display: flex;
   justify-content: space-between;
   align-items: center;
   border-bottom: 1px solid var(--border);
   padding: 20px;
}

.link__name {
   text-transform: capitalize;
   font-size: 1rem;
}

.coin__link a {
   color: var(--pink);
   font-weight: 700;
   font-size: 1rem;
}

.coin__link:hover,
.coin__stats:hover {
   background-color: var(--bgSecondary);
}

@media screen and (max-width:1000px) {
   .stats__container {
      flex-direction: column;
   }

   .coin__desc-link {
      flex-direction: column;
   }

   .stats__container h2 {
      margin-top: 0px;
   }
}

@media screen and (max-width: 500px) {
   .coin__links {
      padding: 0px;
   }

   .coin__detail-container {
      margin: 0;
   }
}

@media screen and (max-width: 500px) {
   .heading {
      margin-top: 20px;
   }
}

.select__news {
   width: 180px;
   margin-bottom: 24px;
}

.news__card {
   min-height: 300px !important;
}

.news__image-container {
   display: flex !important;
   justify-content: space-between !important;
}

.news__title {
   width: 70%;
}

.news__title + img {
   padding-left: 8px;
   width: 100px;
   height: 100px;
}

.news__image-container .img {
   width: 100px;
   height: 100px;
}

.news__card p {
   color: black;
   margin: 10px 0px !important;
}

.provider__container {
   display: flex;
   justify-content: space-between;
}

.provider__name {
   margin-left: 10px;
}

.chart__header {
   display: flex;
   justify-content: space-between;
   gap: 50px;
   color: #0071bd;
}

.chart__title {
   color: #0071bd !important;
}

.price__container {
   display: flex !important;
   gap: 20px !important;
   align-items: center !important;
   flex-wrap: wrap !important;
}

.price__change {
   font-weight: 900 !important;
}

.current__price {
   margin-top: 0px !important;
   font-weight: 900 !important;
}

.home__heading-container {
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin-top: 40px;
}

.show__more {
   margin-top: 0px !important;
}

.exchange__image {
   margin: 0px 10px !important;
}

.search__crypto {
   margin: 20px auto 30px auto;
   width: 250px;
}

.crypto__card-container {
   min-height: 65vh !important;
}

.crypto__card {
   min-width: 250px;
}

.crypto__card .crypto__image {
   width: 35px;
}

.select__timeperiod {
   width: 200px !important;
   margin-top: 20px !important;
}

.footer {
   background-color: #001529;
   display: flex;
   flex-direction: column;
   padding: 20px;
   align-items: center;
}